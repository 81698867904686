// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".my-form-item-group{width:100%}.my-form-item-group .form-item-group_title{font-weight:700;color:#606266;line-height:32px;padding:0 1em;border-bottom:1px solid #e8e8e8;border-right:1px solid #e8e8e8;background-color:#eff7f0}.my-form-item-group .form-item-group_title:before{content:\"◆\";margin-right:.5em}.my-form-item-group .form-item-group_body{display:flex;width:100%;flex-wrap:wrap;position:relative}.my-form-item-group .form-item-group_body:after{content:\"\";display:block;width:0;height:100%;position:absolute;right:0;top:0;border-right:1px solid #e8e8e8}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#61ae6a",
	"primaryColor9": "#71b679",
	"primaryColor8": "#81be88",
	"primaryColor7": "#90c697",
	"primaryColor6": "#a0cea6",
	"primaryColor5": "#b0d7b5",
	"primaryColor4": "#c0dfc3",
	"primaryColor3": "#d0e7d2",
	"primaryColor2": "#dfefe1",
	"primaryColor1": "#eff7f0",
	"successColor": "#52c41a",
	"successColor9": "#63ca31",
	"successColor8": "#75d048",
	"warningColor": "#faad14",
	"warningColor9": "#fbb52c",
	"warningColor8": "#fbbd43",
	"dangerColor": "#ef4040",
	"dangerColor9": "#f15353",
	"dangerColor8": "#f26666",
	"infoColor": "#909399",
	"infoColor9": "#9b9ea3",
	"infoColor8": "#a6a9ad",
	"navBgColor": "#304156",
	"navTextColor": "#bfcbd9",
	"navTextActiveColor": "#ffd04b"
};
module.exports = ___CSS_LOADER_EXPORT___;
